import React, { useState, useCallback } from "react";
import { useBetween } from "use-between";
import "./loading.css";

const useLoading = () => {
  const [loading, setLoading] = useState(`on`);
  const loadingOn = useCallback(() => setLoading((c) => `on`), []);
  const loadingOff = useCallback(() => setLoading((c) => `off`), []);
  return {
    loading,
    loadingOn,
    loadingOff
  };
};

export const useSharedLoading = () => useBetween(useLoading);

export default function Loading(props) {
  const { loading } = useSharedLoading();

  return (
    <div className={`loading-window-${loading}`}>
      <div className="loading-hatchet"></div>
      <div className="loading-earth"></div>
      <div className="loading-planet"></div>
    </div>
  );
}
