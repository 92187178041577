import React from "react";
import syringe from "../img/syringe.png";
import avitar from "../img/avitar.png";
import { usersPhotosDB } from "../game/gameDB/userPhotos.js";

export default function AdminPlaying(props) {
  const aliveUsers = props.alive.map((user) => (
    <div key={user.toString()} className="game-photo-container">
      <button
        className={`game-userButtons`}
        value={user}
        onClick={() => props.handleClick(user)}
      >
        <img
          key={user.toString() + "1"}
          className="game-photo"
          src={usersPhotosDB[user]}
          alt={avitar}
        ></img>
        <h1 key={user.toString() + "2"} className="game-photo-text">
          {user}
        </h1>
        <img
          key={user.toString() + "3"}
          className={`game-photo-selected-${props.activeBtn.includes(user)}`}
          src={syringe}
          alt={user}
        ></img>
      </button>
    </div>
  ));

  const poisonedUsers = props.poisoned.map((user) => (
    <div key={user.toString()} className="game-photo-container">
      <button
        className={`game-userButtons`}
        value={user}
        onClick={() => props.handleClick(user)}
      >
        <img
          key={user.toString() + "4"}
          className="game-photo-poisoned"
          src={usersPhotosDB[user]}
          alt={avitar}
        ></img>
        <h1 key={user.toString() + "5"} className="game-photo-text-poisoned">
          {user}
        </h1>
        <img
          key={user.toString() + "6"}
          className={`game-photo-selected-${props.activeBtn.includes(user)}`}
          src={syringe}
          alt={user}
        ></img>
      </button>
    </div>
  ));

  return (
    <div>
      <div className="game-photos-layout-admin">
        {aliveUsers}
        {poisonedUsers}
      </div>
      <div className="game-button-container">
        <button
          style={{ background: `rgb(123, 247, 156)` }}
          onClick={() => props.handleSubmit(props.activeBtn)}
        >
          Vote
        </button>
        <button
          style={{
            background: `rgb(123, 247, 156)`
          }}
          onClick={() => props.showResults()}
        >
          Results
        </button>
        <button
          style={{ background: `rgb(234, 95, 95)` }}
          onClick={() => props.handleLogout()}
        >
          Logout
        </button>
        <button
          style={{ background: `rgb(234, 95, 95)` }}
          onClick={() => props.handleSleep(props.activeBtn)}
        >
          Sleep
        </button>
        <button
          style={{ background: `rgb(234, 95, 95)` }}
          onClick={() => props.handleAwake(props.activeBtn)}
        >
          Wake
        </button>
      </div>
    </div>
  );
}
