// import React from "react";
import { usersPhotosDB } from "../game/gameDB/userPhotos.js";
import avitar from "../img/avitar.png";

export default function UsersPlaying(props) {
  const aliveUsers = props.alive.map((user) => (
    <div key={user.toString()} className="game-photo-container">
      <img
        key={user.toString() + "1"}
        className="game-photo"
        src={usersPhotosDB[user]}
        alt={avitar}
      ></img>
      <h1 key={user.toString() + "2"} className="game-photo-text">
        {user}
      </h1>
    </div>
  ));

  const poisonedUsers = props.poisoned.map((user) => (
    <div key={user.toString()} className="game-photo-container">
      <img
        key={user.toString() + "4"}
        className="game-photo-poisoned"
        src={usersPhotosDB[user]}
        alt={avitar}
      ></img>
      <h1 key={user.toString() + "5"} className="game-photo-text-poisoned">
        {user}
      </h1>
    </div>
  ));

  return (
    <div>
      <div className="game-photos-layout">
        {aliveUsers}
        {poisonedUsers}
      </div>
      <div className="game-button-container">
        <button
          style={{
            background: `rgb(19, 159, 6)`
          }}
          onClick={() => props.showResults()}
        >
          Results
        </button>
        <button
          style={{
            background: `rgb(234, 95, 95)`
          }}
          onClick={() => props.handleLogout()}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
