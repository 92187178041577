import React, { useState, useEffect } from "react";
import { socket } from "../../socket.js";
import "./waitingResults.css";
import { useSharedLoading } from "../../functions/loading/loading.js";

export default function WaitingPage(props) {
  const [owner, setOwner] = useState(``);
  const [user, setUser] = useState(`test`);
  const [game, setGame] = useState(``);
  const [waitingPage, setWaitingPage] = useState("off");
  const [voters, setVoters] = useState(``);
  const { loadingOn } = useSharedLoading();

  /// Set page on user state
  useEffect(() => {
    socket.on("UserStatus", (data) => {
      setUser(data.user);
      setGame(data.game);
    });
    return () => {
      socket.off("UserStatus");
    };
  }, []);
  //// set the vot page
  useEffect(() => {
    /// Set game data
    socket.on("WaitingResults", (data) => {
      if (data.voters.length >= 1 && !data.voters.includes(user)) {
        setGame(data.game);
        setOwner(data.owner === user ? `on` : `off`);
        let voterString = data.voters[0];
        for (let i = 1; i < data.voters.length; i++) {
          if (i === data.voters.length - 1) {
            voterString += ` and ${data.voters[i]} `;
          } else voterString += `, ${data.voters[i]} `;
        }
        setVoters(voterString);
        setWaitingPage(`on`);
        loadingOn();
      } else {
        setWaitingPage(`off`);
      }
    });
    return () => {
      socket.off("WaitingResults");
    };
  }, [user, loadingOn]);

  // Submit button
  const handleSubmit = (e) => {
    socket.emit(`EndWaitingResults`, { user: user, owner: ``, game: game });
  };

  return (
    <div className={`waiting-page-${waitingPage}`}>
      <div className="waiting-text-container">
        <div className="waiting-text">Players {voters} still left to vote.</div>
      </div>
      <button className={`waiting-btn-${owner}`} onClick={() => handleSubmit()}>
        End vote early
      </button>
    </div>
  );
}
