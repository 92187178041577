import React, { useState, useEffect } from "react";
import { socket } from "../socket.js";
import "./join.css";
import ChooseYesNo from "../functions/popUp/popUpChoose";
import { useSharedLoading } from "../functions/loading/loading";
import { usersPhotosDB } from "../game/gameDB/userPhotos.js";

export default function Join(props) {
  const [joinPage, setJoinPage] = useState("off");
  const [game, setGame] = useState(``);
  const [passcode, setPasscode] = useState(``);
  const [user, setUser] = useState([]);
  const [userPhoto, setUserPhoto] = useState(``);
  const [create, setCreate] = useState(false);
  const [logoutSect, setLogoutSect] = useState("off");
  const [resetPhoto, setResetPhoto] = useState("off");
  const { loadingOn, loadingOff } = useSharedLoading();

  useEffect(() => {
    socket.on("UserStatus", (data) => {
      if (data.page === `joinGame`) {
        setUser(data.user);
        if (usersPhotosDB[data.user]) {
          setUserPhoto(usersPhotosDB[data.user]);
        } else {
          setUserPhoto(data.photo.img);
        }
        setJoinPage(`on`);
        loadingOff();
      }
    });
    return () => {
      socket.off("UserStatus");
    };
  }, [loadingOff]);

  ////Handle username text box
  const handleChange = (e) => {
    if (e && e.slice(-1) === ` `) {
      alert(`No spaces allowed`);
    } else if (e.length > 20) {
    } else {
      setGame(e);
    }
  };
  ///handle passcode text box
  const handleChangePasscode = (e) => {
    if (e && e.slice(-1) === ` `) {
      alert(`No spaces allowed`);
    } else if (e.length > 6) {
    } else {
      setPasscode(e);
    }
  };

  /// Submit button
  const handleSubmit = (e) => {
    if (passcode.length < 4) alert(`Passcode must be 4 characters or more!`);
    else if (game.length < 4) alert(`Game name must be 4 characters or more!`);
    ///////creat new user
    else if (create) {
      loadingOn();
      setJoinPage(`off`);
      socket.emit(
        "CreateGame",
        { user: user, game: game, passcode: passcode },
        function (res) {
          if (res.res === false) {
            setJoinPage(`on`);
            loadingOff();
            alert(`Game name ${game} is already taken`);
          } else {
            setGame("");
            setPasscode("");
          }
        }
      );
    }
    ///login user
    else {
      loadingOn();
      setJoinPage(`off`);
      socket.emit(
        "JoinGame",
        { user: user, game: game, passcode: passcode },
        function (res) {
          if (res.res === true) {
            setGame("");
            setPasscode("");
          } else if (res.res === false) {
            setJoinPage(`on`);
            loadingOff();
            alert(`Game does not exist`);
          } else {
            setJoinPage(`on`);
            loadingOff();
            alert(`Passcode incorrect Please Try Again`);
          }
        }
      );
    }
  };

  ///Set the create status and empty fileds
  const createStatus = () => {
    // setGame("");
    // setPasscode("");
    //####### change to !creat when want users to creat game
    setCreate(!create);
    // alert(`Sorry game underdevelopment`);
  };
  /// logoff user
  const logUserOut = () => {
    setLogoutSect(`on`);
  };

  const logoutYes = () => {
    loadingOn();
    setJoinPage(`off`);
    setLogoutSect(`off`);
    socket.emit("LogOutUser", { user: user });
  };

  const logoutNo = () => {
    setLogoutSect(`off`);
  };

  //Change your photo the photo
  const resetPhotoYesNo = () => {
    setResetPhoto(`on`);
  };

  const resetPhotoYes = () => {
    loadingOn();
    setJoinPage(`off`);
    setResetPhoto(`off`);
    socket.emit("ChangePhoto", { user: user });
  };

  const resetPhotoNo = () => {
    setResetPhoto(`off`);
  };

  ////React join page
  return (
    <div
      className={`join-sheet-${joinPage}`}
      // style={{ display: loginPage ? "visible" : "none" }}
    >
      <div className="join-photo-container" onClick={() => resetPhotoYesNo()}>
        <img className="join-photo" src={userPhoto} alt="userPhoto"></img>
        <h1 className="join-text">{user}</h1>
      </div>
      <div className="join-text-container">
        <h2 className="join-join">
          {create ? "Create New Game:" : "Join an Existing game:"}
        </h2>
        <div className="game-form">
          <input
            placeholder="Game name"
            value={game}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        <div>
          <input
            placeholder="Passcode"
            value={passcode}
            onChange={(e) => handleChangePasscode(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={() => {
              setGame(`MarksGame`);
              setPasscode(`1111`);
            }}
          >
            Test Game
          </button>
          <button onClick={() => handleSubmit()}>
            {create ? "Create" : "Join Game"}
          </button>
        </div>
        <div>
          <p className="join-join">Or:</p>
          <button onClick={() => createStatus()}>
            {create ? "Join Existing Game" : "Creat New Game"}
          </button>
          <button
            style={{ background: `rgb(234, 95, 95)` }}
            onClick={() => logUserOut()}
          >
            {"Log Out"}
          </button>
        </div>
      </div>
      <ChooseYesNo
        heading={`Confirm Logout`}
        message={`Are you sure you want to logout.`}
        logoutYes={logoutYes}
        logoutNo={logoutNo}
        logoutSect={logoutSect}
      />
      <ChooseYesNo
        heading={`Confirm Photo Change`}
        message={`Are you sure you want to change your photo.`}
        logoutYes={resetPhotoYes}
        logoutNo={resetPhotoNo}
        logoutSect={resetPhoto}
      />
    </div>
  );
}
