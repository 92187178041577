import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import "./camera.css";
import { socket } from "../socket.js";
import avitar from "../img/avitar.png";
import { useSharedLoading } from "../functions/loading/loading";
import { updatePhoto } from "../game/gameDB/userPhotos";

//Video size
const videoConstraints = {
  width: 500,
  height: 500,
  facingMode: "user"
};

export default function Camera(props) {
  const [showCamera, setShowCamera] = useState(false);
  const webcamRef = useRef(null);
  const [url, setUrl] = useState(null);
  const [user, setUser] = useState(``);
  const [cameraPage, setCameraPage] = useState("off");
  const { loadingOn, loadingOff } = useSharedLoading();

  useEffect(() => {
    socket.on("UserStatus", (data) => {
      if (data.page === `camera`) {
        setUser(data.user);
        setCameraPage(`on`);
        loadingOff();
      }
    });
  }, [loadingOff]);

  //Show camera
  const setCamera = () => {
    setShowCamera(!showCamera);
  };

  //Camera
  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);

  async function submitPhoto() {
    loadingOn();
    let ver = new Date().getTime();
    let passed = await updatePhoto({ user: user, photo: url, ver: ver });
    if (passed === true) {
      setCameraPage(`off`);
      socket.emit(
        "SubmitPhoto",
        { user: user, photo: url, ver: ver },
        function (res) {
          if (res.res === true) {
            setUrl(null);
            setShowCamera(false);
          } else {
            alert(`Please try again Photo did not save!`);
            setCameraPage(`on`);
            loadingOff();
          }
        }
      );
    } else {
      alert(`Failed to save photo with err${passed}`);
      loadingOff();
    }
  }

  return (
    <div
      className={`camera-sheet-${cameraPage}`}
      // style={{ display: gamePage ? "visible" : "none" }}
    >
      <div className="camera-text-cont">
        <h1 className="camera-heading">Welcome {user}</h1>
        <h1 className="camera-welcome">Please add photo of yourself</h1>
      </div>
      <div>
        {url && (
          <div>
            <img className="camera-module" src={url} alt="Screenshot" />
          </div>
        )}
        {showCamera && !url && (
          <div className="camera-module">
            <Webcam
              className="camera-webcam"
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/png"
              videoConstraints={videoConstraints}
              mirrored={true}
            />
          </div>
        )}
        {!showCamera && (
          <img
            className="camera-module"
            src={avitar}
            alt="avitar"
            width="300"
            height="300"
          />
        )}
      </div>
      <div className="camera-button-container">
        <button className="camera-button-camera" onClick={setCamera}></button>
        <button
          className="camera-button-capture"
          onClick={capturePhoto}
        ></button>
        <button
          className="camera-button-reset"
          onClick={() => setUrl(null)}
        ></button>
        <button className="camera-button-file"></button>
      </div>
      <button
        className="camera-button-submit"
        disabled={!url}
        onClick={submitPhoto}
      >
        Save
      </button>
    </div>
  );
}
