import React, { useState, useEffect, useRef } from "react";
import { socket } from "../../socket.js";
import syringe from "../../img/syringe.png";
import avitar from "../../img/avitar.png";
import "./resultVote.css";
import { useSharedLoading } from "../../functions/loading/loading";
import { usersPhotosDB } from "../../game/gameDB/userPhotos.js";

export default function ResultPage(props) {
  const [resultPage, setResultPage] = useState("off");
  const [roundSelect, setRoundSelect] = useState("off");
  const [reVote, setRevote] = useState(`off`);
  const [timer, setTimer] = useState(10);
  const [curRound, setCurRound] = useState(1);
  const results = useRef({
    1: { nominated: { [`No-Body`]: [] } }
  });
  const { loadingOff } = useSharedLoading();

  useEffect(() => {
    socket.on("PlayerKilled", (data) => {
      if (data.results !== `setPage`) {
        results.current = data.results;
        setCurRound(data.results.rounds);
        if (data.reVote.yes) {
          setRevote(data.reVote);
          setRevote(`on`);
          closeResultPage();
          setTimer(10);
        }
      }
      setResultPage("on");
      loadingOff();
    });
    return () => {
      socket.off("PlayerKilled");
    };
  }, [loadingOff]);

  // Submit button
  const handleSubmit = (e) => {
    setResultPage("off");
  };

  const closeResultPage = () => {
    setTimeout(() => {
      setResultPage("off");
      setRevote(`off`);
    }, 10000);
  };

  useEffect(() => {
    if (reVote === `on`) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(interval);
    }
  }, [timer, reVote]);

  const displayRounsBtns = () => {
    if (roundSelect === `on`) {
      setRoundSelect(`off`);
    } else setRoundSelect(`on`);
  };

  const selectRound = (e) => {
    setRoundSelect(`off`);
    setCurRound(e);
  };

  const whoVoted = Object.keys(results.current[curRound].nominated).map(
    (nUser) => (
      <div key={nUser.toString() + `con`} className="result-voter-container">
        <div key={nUser.toString()} className="result-photo-container">
          <img
            key={nUser.toString() + "1"}
            className="result-photo"
            src={usersPhotosDB[nUser] || avitar}
            alt={avitar}
          ></img>
          <h1 key={nUser.toString() + "2"} className="result-photo-text">
            {nUser}
            <img
              key={nUser.toString() + "3"}
              className={`result-photo-selected-${
                nUser === results.current[curRound].poisoned ? `true` : `false`
              }`}
              src={syringe}
              alt={nUser}
            ></img>
          </h1>
        </div>
        {results.current[curRound].nominated[nUser].map((vUser) => (
          <div key={vUser.toString()} className="result-photo-container-voters">
            <img
              key={vUser.toString() + "4"}
              className="result-photo"
              src={usersPhotosDB[vUser] || avitar}
              alt={avitar}
            ></img>
            <h1
              key={vUser.toString() + "5"}
              className="result-photo-text-voters"
            >
              {vUser}
            </h1>
          </div>
        ))}
      </div>
    )
  );

  function setRounds() {
    const btnRounds = [];
    for (let i = 1; i <= results.current.rounds; i++) {
      btnRounds.push(
        <button
          key={`button${i}`}
          className={`result-number`}
          value={i}
          onClick={() => selectRound(i)}
        >{`Round ${i}`}</button>
      );
    }
    return (
      <div
        key={`btnSelect`}
        className={`result-number-container-${roundSelect}`}
      >
        {btnRounds}
      </div>
    );
  }

  return (
    <div className={`result-page-${resultPage}`}>
      <div className="result-alph-layer">
        <span className="result-killed-text">
          {results.current[curRound].poisoned} has been put into cryosleep!
        </span>
        <div className={`result-photos-layout`}>{whoVoted}</div>
        <div>
          <button
            className="result-btn-left"
            onClick={() => displayRounsBtns()}
          >
            {`Round ${curRound}`}
          </button>
          <button className="result-btn-right" onClick={() => handleSubmit()}>
            Close
          </button>
          <button className={`result-revote-${reVote}`}>
            {`Draw: Revote in ${timer} seconds with no option to Absten!`}
          </button>
          <div>{setRounds()}</div>
        </div>
      </div>
    </div>
  );
}
