import React from "react";
import "./popUpChoose.css";

export default function ChooseYesNo(props) {
  return (
    <div className={`popup-window-${props.logoutSect}`}>
      <div className="popup-window">
        <h1 className="popup-heading">{props.heading}</h1>
        <p className="popUp-message">{props.message}</p>
        <div>
          <button className="popup-yes" onClick={() => props.logoutYes()}>
            Yes
          </button>
          <button className="popup-no" onClick={() => props.logoutNo()}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}
