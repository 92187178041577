import React, { useState, useEffect } from "react";
import { socket } from "../../socket.js";
import syringe from "../../img/syringe.png";
import cancel from "../../img/cancel.png";
import avitar from "../../img/avitar.png";

import "./votePage.css";
import { useSharedLoading } from "../../functions/loading/loading";
import { usersPhotosDB } from "../gameDB/userPhotos.js";

export default function VotePage(props) {
  const [user, setUser] = useState();
  const [game, setGame] = useState("");
  const [owner, setOwner] = useState("");
  const [votePage, setVotePage] = useState("off");
  const [voteToKill, setVoteToKill] = useState([]);
  const [voteBtn, setVoteBtn] = useState([]);
  const { loadingOn, loadingOff } = useSharedLoading();

  //// set the vot page
  useEffect(() => {
    socket.on("UserStatus", (data) => {
      setUser(data.user);
    });
    return () => {
      socket.off("UserStatus");
    };
  }, []);

  useEffect(() => {
    /// Set game data
    socket.on("AllVote", (data) => {
      //names to choose from
      if (data.owner === ``) {
        setVotePage("off");
      } else if (data.owner === user) {
        setVotePage("off");
        socket.emit("VoteCount", {
          vote: false,
          user: user,
          voters: data.voters,
          owner: data.owner,
          game: data.game
        });
      } else {
        setOwner(data.owner);
        setGame(data.game);
        setVoteToKill(data.nominated);
        setVotePage("on");
        loadingOff();
      }
    });
    return () => {
      socket.off("AllVote");
    };
  }, [loadingOff, user]);

  //Handle username text box
  const handleClick = (e) => {
    setVoteBtn(e);
  };

  // Submit button
  const handleSubmit = (e) => {
    if (e <= []) alert(`Please select at least one player`);
    else {
      loadingOn();
      socket.emit("VoteCount", {
        user: user,
        game: game,
        vote: voteBtn,
        owner: owner
      });
      setVoteBtn([]);
      setVotePage("off");
    }
  };

  const listUsers = voteToKill.map((user) => (
    <div key={user.toString()} className="vote-photo-container">
      <button
        className={`vote-userButtons`}
        value={user}
        onClick={() => handleClick(user)}
      >
        <img
          key={user.toString() + "1"}
          className="vote-photo"
          src={user === `No-Body` ? avitar : usersPhotosDB[user]}
          alt={avitar}
        ></img>
        <h1 key={user.toString() + "2"} className="vote-photo-text">
          {user}
        </h1>
        <img
          key={user.toString() + "3"}
          className={`vote-photo-selected-${voteBtn.includes(user)}`}
          src={user === `No-Body` ? cancel : syringe}
          alt={user}
        ></img>
      </button>
    </div>
  ));

  return (
    <div className={`vote-page-${votePage}`}>
      <div className="vote-text-container">Vote to put into Cryonics!</div>
      <div className={`vote-photos-layout`}>{listUsers}</div>
      <div>
        <button className="vote-btn" onClick={() => handleSubmit(voteBtn)}>
          Vote
        </button>
      </div>
    </div>
  );
}
