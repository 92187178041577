import { localDB } from "../../localDB.js";
import { socket } from "../../socket.js";

export let usersPhotosDB = {};

async function updateUserPhotos() {
  try {
    await localDB.usersPhotos.each((users) => {
      usersPhotosDB[users.name] = users.photo;
    });
  } catch (err) {
    alert(`Failed to load DataBase err = ${err}. Please refresh page`);
  }
}
//###################################################
/// Checked version and send reply
//###################################################
socket.on("PhotoVersions", async function (data, callback) {
  let rtn = [];

  for (const [key, value] of Object.entries(data)) {
    let user = await localDB.usersPhotos.get(key);
    if (!user || user.ver < value) {
      rtn.push(key);
    }
  }
  callback(rtn);
});

//####################################################
///Update the user database mutiple
//####################################################
socket.on("UserPhotos", (data, callback) => {
  for (const [key, value] of Object.entries(data)) {
    localDB.usersPhotos.put({
      name: key,
      photo: value.img,
      ver: value.ver
    });
  }
  // Update Local userPhotos
  updateUserPhotos();
  callback(true);
});
//###################################################
///Update the user database single user
//###################################################
socket.on("UserPhoto", (data) => {
  localDB.usersPhotos.put({
    name: data.user,
    photo: data.photo.img,
    ver: data.photo.ver
  });

  // Update Local userPhotos
  updateUserPhotos();
});

export async function updatePhoto(data) {
  try {
    await localDB.usersPhotos.put({
      name: data.user,
      photo: data.photo,
      ver: data.ver
    });

    updateUserPhotos();
    return true;
  } catch (err) {
    return err;
  }
}

updateUserPhotos();
