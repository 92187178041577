import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
// import { io } from "socket.io-client";
import "./index.css";

//Import web pages
import Login from "./login/login.js";
import Join from "./join/join.js";
import Game from "./game/game.js";
import Camera from "./camera/camera.js";
import VotePage from "./game/voting/votePage.js";
import ResultPage from "./game/voting/resultVote.js";
import Loading from "./functions/loading/loading";
import WaitingPage from "./game/voting/waitingResults";

// // const ENDPOINT = "https://89.117.37.170/";
// const ENDPOINT = "http://localhost:4001/";
// export const socket = io(ENDPOINT);

ReactDOM.render(
  <React.StrictMode>
    <VotePage />
    <ResultPage />
    <Camera />
    <Game />
    <Login />
    <Join />
    <Loading />
    <WaitingPage />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
