import React, { useState, useEffect } from "react";
import "./game.css";
import { socket } from "../socket.js";

import UsersPlaying from "./bgUserPhotos.js";
import AdminPlaying from "./bgAdminPhotos.js";
import ChooseYesNo from "../functions/popUp/popUpChoose.js";
import { useSharedLoading } from "../functions/loading/loading";

export default function Game(props) {
  const [game, setGame] = useState("");
  const [user, setUser] = useState(``);
  const [owner, setOwner] = useState(``);
  const [alive, setAlive] = useState([]);
  const [poisoned, setPoisoned] = useState([]);
  const [gamePage, setGamePage] = useState("off");
  const [userSect, setUserSect] = useState("off");
  const [adminSect, setAdminSect] = useState("off");
  const [activeBtn, setActiveBtn] = useState([]);
  const [logoutSect, setLogoutSect] = useState("off");
  const { loadingOn, loadingOff } = useSharedLoading();

  /// Set page on user state
  useEffect(() => {
    socket.on("UserStatus", (data) => {
      setUser(data.user);
    });
    return () => {
      socket.off("UserStatus");
    };
  }, []);

  useEffect(() => {
    /// Set game data
    socket.on("GameStatus", (data) => {
      setAlive(data.alive);
      setPoisoned(data.poisoned);
      setGame(data.game);
      setOwner(data.owner);

      if (data.owner === user) {
        setUserSect("off");
        setAdminSect("on");
      } else {
        setUserSect("on");
        setAdminSect("off");
      }
      loadingOff();
      setGamePage(`on`);
    });
    return () => {
      socket.off("GameStatus");
    };
  }, [loadingOff, user]);

  //Handle username text box
  const handleClick = (e) => {
    if (activeBtn.includes(e)) {
      setActiveBtn((activeBtn) => activeBtn.filter((elt) => elt !== e));
    } else {
      setActiveBtn((activeBtn) => [...activeBtn, e]);
    }
  };

  // Submit button
  const handleSubmit = (e) => {
    if (e <= []) alert(`Please select at least one player`);
    else if (poisoned.some((e) => activeBtn.includes(e))) {
      alert(`Only select no-cryo sleeping users`);
    } else {
      loadingOn();
      socket.emit("VoteAdmin", {
        game: game,
        nominated: [...activeBtn, `No-Body`],
        owner: owner
      });
      setActiveBtn([]);
    }
  };
  const handleSleep = (e) => {
    if (e <= []) alert(`Please select at least one player`);
    else {
      socket.emit("UserSleep", { user: user, game: game, users: activeBtn });
      setActiveBtn([]);
    }
  };
  const handleAwake = (e) => {
    if (e <= []) alert(`Please select at least one player`);
    else {
      socket.emit("UserAwake", { user: user, game: game, users: activeBtn });
      setActiveBtn([]);
    }
  };

  const handleLogout = () => {
    setLogoutSect(`on`);
  };

  const logoutYes = () => {
    loadingOn();
    setLogoutSect(`off`);
    setGamePage(`off`);
    socket.emit("LeaveGame", { user: user, game: game }, function (res) {
      //call loading until get responce to implement
      if (res.res !== true) {
        alert(`Sorry failed to log you out please try again`);
        loadingOff();
        setLogoutSect(`on`);
        setGamePage(`on`);
      }
    });
  };

  const logoutNo = () => {
    setLogoutSect(`off`);
  };

  const showResults = () => {
    socket.emit("ShowResults", { results: `setPage` });
  };

  return (
    <div>
      <div
        className={`game-sheet-${gamePage}`}
        // style={{ display: gamePage ? "visible" : "none" }}
      >
        <div className={"game-text-container"}>
          {game}
          <span>
            {" "}
            ~ {owner}~{user}
          </span>
        </div>
        <div className={`game-user-sheet-${userSect}`}>
          <UsersPlaying
            alive={alive}
            poisoned={poisoned}
            handleLogout={handleLogout}
            showResults={showResults}
          />
        </div>
        <div className={`game-admin-sheet-${adminSect}`}>
          <AdminPlaying
            alive={alive}
            poisoned={poisoned}
            handleClick={handleClick}
            activeBtn={activeBtn}
            handleSubmit={handleSubmit}
            handleLogout={handleLogout}
            handleSleep={handleSleep}
            handleAwake={handleAwake}
            showResults={showResults}
          />
        </div>
      </div>
      <ChooseYesNo
        heading={`Logout from Game`}
        message={`Are you sure you want to logout of the game. All progress will be lost.`}
        logoutYes={logoutYes}
        logoutNo={logoutNo}
        logoutSect={logoutSect}
      />
    </div>
  );
}
