import React, { useState, useEffect } from "react";
// import Testing from "./testing.js";
import "./login.css";
import { socket } from "../socket.js";
import Cookies from "js-cookie";
import { useSharedLoading } from "../functions/loading/loading.js";

export default function Login(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [create, setCreate] = useState(false);
  const [loginPage, setLoginPage] = useState("off");
  const { loadingOn, loadingOff } = useSharedLoading();

  ///Check if user is logged on on load&refresh
  useEffect(() => {
    if (Cookies.get(`DinnerOfDeathUser`)) {
      socket.emit(
        `LoggedOn`,
        JSON.parse(Cookies.get(`DinnerOfDeathUser`)),
        function (res) {
          if (res.res === true) {
            let idToken = JSON.stringify({ user: res.user, id: socket.id });
            Cookies.set(`DinnerOfDeathUser`, idToken, {
              expires: 2,
              secure: true
            });
          } else {
            setLoginPage("on");
            loadingOff();
          }
        }
      );
    } else {
      setLoginPage("on");
      loadingOff();
    }
  }, [loadingOff]);

  useEffect(() => {
    socket.on("UserStatus", (data) => {
      if (data.page === `login`) {
        setLoginPage("on");
        loadingOff();
      }
    });
    return () => {
      socket.off("UserStatus");
    };
  }, [loadingOff]);

  ////Handle username text box
  const handleChange = (e) => {
    if (e && e.slice(-1) === ` `) {
      alert(`No spaces allowed`);
    } else if (e.length > 7) {
    } else if (e) {
      setName(e[0].toUpperCase() + e.slice(1).toLowerCase());
    } else if (e.length > 7) {
    } else {
      setName(e);
    }
  };
  ///handle password text box
  const handleChangePassword = (e) => {
    if (e && e.slice(-1) === ` `) {
      alert(`No spaces allowed`);
    } else if (e.length > 6) {
    } else {
      setPassword(e);
    }
  };

  /// Submit button
  const handleSubmit = (e) => {
    // var userId = [Math.random().toString(36).substring(3, 16), Date.now()];
    if (password.length < 4) alert(`Password must be 4 characters or more!`);
    else if (name.length < 4) alert(`Username must be 4 characters or more!`);
    else if (name === `NoBody`) alert(`User name already taken`);
    ///////creat new user
    else if (create) {
      loadingOn();
      setLoginPage("off");
      socket.emit(
        "CreateUsers",
        { user: name, password: password },
        function (res) {
          if (res.res === true) {
            let idToken = JSON.stringify({ user: name, id: socket.id });
            Cookies.set(`DinnerOfDeathUser`, idToken, {
              expires: 1,
              secure: true
            });
            setName("");
            setPassword("");
          } else if (res.res === false) {
            setLoginPage("on");
            loadingOff();
            alert(`User name ${name} is already taken`);
          } else if (res.res === 0) {
            setLoginPage("on");
            loadingOff();
            alert(`Failed to save, server error please try again`);
          } else if (res.res === -1) {
            setLoginPage("on");
            loadingOff();
            alert(`Failed to confirm is user name is avalible`);
          }
        }
      );
    }
    ///login user
    else {
      loadingOn();
      setLoginPage("off");
      socket.emit(
        "LoginUsers",
        { user: name, password: password },
        function (res) {
          if (res.res === true) {
            let idToken = JSON.stringify({ user: name, id: socket.id });
            Cookies.set(`DinnerOfDeathUser`, idToken, {
              expires: 1,
              secure: true
            });
            setName("");
            setPassword("");
          } else if (res.res === false) {
            setLoginPage("on");
            loadingOff();
            alert(`User does not exist`);
          } else if (res.res === -1) {
            setLoginPage("on");
            loadingOff();
            alert(`Unable to connect to DataBase please try again`);
          } else {
            setLoginPage("on");
            loadingOff();
            alert(
              res.res <= 0
                ? `Your account is locked please contact Admin`
                : `Password incorrect Please Try Again:
                Number of guesses left = ${res.res}`
            );
          }
        }
      );
    }
  };

  const createStatus = () => {
    setName("");
    setPassword("");
    setCreate(!create);
  };

  return (
    <div
      className={`login-sheet-${loginPage}`}
      // style={{ display: loginPage ? "visible" : "none" }}
    >
      {" "}
      <div className="login-container">
        <h1 className="login-welcome">Space</h1>
        {/* <h1 className="login-welcome-of">Of</h1> */}
        <h1 className="login-welcome">Sabatage</h1>
      </div>
      <div className="login-form-container">
        <h2 className="login-login">
          {create ? "Create New Account:" : "Please login:"}
        </h2>
        <div className="login-form">
          <input
            placeholder="Username"
            value={name}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        <div>
          <input
            placeholder="Password"
            value={password}
            onChange={(e) => handleChangePassword(e.target.value)}
          />
        </div>
        <div>
          <button onClick={() => handleSubmit()}>
            {create ? "Create" : "Login"}
          </button>
          <button onClick={() => createStatus()}>
            {create ? "Existing user login" : "Creat new User"}
          </button>
        </div>
      </div>
      <div className="testing"></div>
    </div>
  );
}
